import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography
} from '@mui/material';
import logo from './logo.svg';

const Header = ()=> (
  <AppBar position="static">
    <Toolbar variant="dense">
      <img src={logo} style={{ height: 20, paddingRight: 24 }} alt="ДТЕК" />
      <Typography variant="h6" color="inherit" component="div" style={{ lineHeight: 1.2 }} >
        Цифровий скоринг контрагентів
      </Typography>
    </Toolbar>
  </AppBar>
)

export default Header;
