import React, { useState } from 'react';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import {
  TextField,
  Button,
  Typography,
  Stack,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import BlockSharp from '@mui/icons-material/BlockSharp';
import axios from 'axios';
import Loader from '../Loader';

const Field = ({field: {label, value, notRequired} = {}}) => {
  if (notRequired && !value) return null;
  return (
    <Grid item md={6} xs={12}>
      <div style={{border: 'solid 1px #ccc', padding: 8}}>
        <div style={{fontSize: 12, lineHeight: 1.2, color: '#202020'}}>{label}</div>
        <div style={{fontSize: 16, lineHeight: 1.6, color: '#000'}}>{value}</div>
      </div>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  tr: {
    position: 'relative',
    boxShadow: 'inset 0 1px #ccc'
  },
  isRisked: {
    '& :before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      border: 'dotted 2px #d20',
    }
  }
}));


const Content = () => {
  const classes = useStyles();
  let API_DOMAIN = 'prc-002-stop.k8s-app-dev.dtek.cloud/be';

  if (process.env.REACT_APP_API_URL) { API_DOMAIN = process.env.REACT_APP_API_URL; }
  const [value, setValue] = useState(''); //44303859
  const [statusCode, setStatus] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [isTableShow, setTableShow] = useState(false);

  const [data, setData] = useState(null);
  const handleClick = async () => {
    setTableShow(false);
    setLoading(true);
    setLoaded(false);
    const {data, status} = await axios.get(`${window.location.protocol}//${API_DOMAIN}/api/scoring/data?personalCode=${value}`);
    setStatus(status);
    setData(data);
    setLoading(false);
    setLoaded(true);
  };
  const fields = [
    {
      label: 'Скорочена назва організації',
      value: data?.shortName
    }, {
      label: 'Код ЄДРПОУ',
      value: data?.edrpou
    },
    {
      label: 'Адреса',
      value: data?.organizationAddress
    },
    {
      label: 'ПІБ Керівника',
      value: data?.chiefName
    },
    {
      label: 'Статус реєстрації',
      value: data?.state
    },
    {
      label: 'Код ІПН (ПДВ)',
      value: data?.inn,
      notRequired: true
    },
    {
      label: 'Наявний податковий борг',
      value: data?.hasBorg ? 'так' : 'ні'
    },
    {
      label: 'Наявні санкції',
      value: data?.inSanctions ? 'так' : 'ні'
    },
    {
      label: 'Наявні виконавчі провадження',
      value: data?.introduction ? `так: ${data.introduction}` : 'ні'
    },
    {
      label: 'Розмір статутного капіталу',
      value: data?.authorisedCapitalValue,
      notRequired: true
    },
    {
      label: 'Дата формування капіталу',
      value: data?.authorisedCapitalDate ? format(new Date(parseISO(data.authorisedCapitalDate)), 'dd.MM.yyyy') : null,
      notRequired: true
    },
    {
      label: 'Дата державної реєстрації',
      value: data?.organizationRegistrationDate ? format(new Date(parseISO(data.organizationRegistrationDate)), 'dd.MM.yyyy') : null,
      notRequired: true
    },
  ];
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        style={{
          position: 'relative',
          padding: '16px 0'
        }}
      >
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label="ЄДРПОУ"
          variant="outlined"
        />
        <Button onClick={handleClick} variant="contained" startIcon={<SearchIcon/>}>Пошук</Button>
      </Stack>
      {isLoading && <Loader/>}
      {isLoaded && (statusCode === 204) && (
        <div style={{textAlign: 'center', lineHeight: 1.4 }}>
          Для данного <b>ЄДРПОУ</b> відсутня інформація. Перевірте коректність введених даних
        </div>
      )}
      {!isLoading && isLoaded && (statusCode === 200) && data && (
        <>
          <div>Повна назва організації</div>
          <Typography gutterBottom variant="h5" component="h2">
            {data?.name}
          </Typography>
          <Grid container spacing={1}>
            {fields?.map(field => <Field key={field?.label} field={field}/>)}
          </Grid>
          {!isTableShow && (
            <div style={{marginTop: 16, display: 'flex', justifyContent: 'center'}}>
              <Button onClick={() => setTableShow(true)} variant="contained">Скоринг</Button>
            </div>
          )}
          {isTableShow && data?.risks && (
            <div style={{
              marginTop: '16px',
              background: '#fff',
              maxWidth: '100%',
              overflowX: 'auto'
            }}>
              <Typography gutterBottom variant="h6" component="h5">
                Результат скорингу
              </Typography>
              <Table style={{ width: 'calc(100% - 4px)', minWidth: 500 }}>
                <TableHead>
                  <TableRow>
                    <TableCell> </TableCell>
                    <TableCell align="right">Пройдено / Не пройдено</TableCell>
                    <TableCell align="right">Код ризику</TableCell>
                    <TableCell align="right">Зона ризику</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.risks?.map(
                    ({code, name, zone, isRisked}) => {
                      // const color = isRisked ? '#d20' : 'transparent';
                      return (
                        <TableRow
                          key={name}
                          className={clsx(classes.tr, {
                            [classes.isRisked]: isRisked
                          })}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                          >{name}</TableCell>
                          <TableCell align="center">{isRisked ? <BlockSharp/> : <CheckIcon/>}</TableCell>
                          <TableCell align="right">{isRisked ? code : ''}</TableCell>
                          <TableCell align="right">{isRisked ? zone : ''}</TableCell>
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Content;
