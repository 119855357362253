import React from 'react';
import {
  Paper,
  Container,
  Typography,
} from '@mui/material';
import Content from '../Content';
import search from './search.svg';

const Wrapper = ()=> {
  return (
    <Container>
      <Paper elevation={3} style={{ marginTop: 24, marginBottom: 24, minHeight: 'calc( 100vh - 112px )', padding: '0 16px 16px' }}>
        <img
          style={{ display: 'block', maxWidth: '80%', margin: '8px auto 0' }}
          src={search}
          alt="search"
        />
        <Typography style={{ textAlign: 'center' }} variant="h3" color="inherit" component="div">
          Доброго дня!
        </Typography>
        <Typography style={{ textAlign: 'center', lineHeight: 1.3 }} variant="h6" color="inherit" component="div">
          Для пошуку контрагента скористайтеся формою
        </Typography>
        <Content />
      </Paper>
    </Container>
  )
}

export default Wrapper;
