import React from 'react';
import Header from '../Header';
import Wrapper from '../Wrapper';

function App() {
  return (
    <>
      <Header />
      <Wrapper />
    </>
  );
}

export default App;
