import React from 'react';
import { CircularProgress } from '@mui/material';

function Loader() {
  return (
    <div style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      zIndex: 2,
    }}>
      <CircularProgress />
    </div>
  );
}

export default Loader;
